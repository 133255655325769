@import "primeicons/primeicons.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
/* You can add global styles to this file, and also import other style files */
body {
  height: 100vh;
  margin: 0px;
}
:root {
  --headerHeight: calc(100vh - 57px);
}
#activityTimeline .p-timeline-event-opposite {
  display: none;
}

p-treeTable p-checkbox {
  vertical-align: text-bottom;
}

.docUploadButton {
  color: #64748b;
  background-color: transparent;
  border: 1px solid #e2e8f0;
}
.docUploadButton .p-button-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 125px;
  overflow: hidden;
  font-size: 12px;
}

.felix-select-outline {
  outline: 1px solid #10B189;
}
.accountsDropdown .p-dropdown-label {
  font-size: 14px;
}
.pac-container { z-index: 10000 !important; }
